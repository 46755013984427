import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//获取原型对象上的push函数
// const VueRouterPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(to) {
//     return VueRouterPush.call(this, to).catch(err => err)
// }
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location) {
//     return originalPush.call(this, location).catch(err => err)
// }


// 获取原型对象push函数
// const originalPush = VueRouter.prototype.push
//
// // 获取原型对象replace函数
// const originalReplace = VueRouter.prototype.replace
//
// // 修改原型对象中的push函数
// VueRouter.prototype.push = function push(location){
//     return originalPush.call(this , location).catch(err=>err)
// }
//
// // 修改原型对象中的replace函数
// VueRouter.prototype.replace = function replace(location){
//     return originalReplace.call(this , location).catch(err=>err)
// }


const routes = [

    {
        path: '/',
        redirect: '/reserve'
    },
    {
        path: '/main',
        name: 'main',
        component: () => import(/* webpackChunkName: "about" */ '../views/tlias/MainView.vue')
    },
    {
        path: '/mine',
        name: 'mine',
        component: () => import( '../views/tlias/MineView.vue'),
    },

    {
        path: '/game',
        name: 'game',
        component: () => import(/* webpackChunkName: "about" */ '../views/tlias/GameView.vue')
    },

    {
        path: '/room',
        name: 'room',
        component: () => import(/* webpackChunkName: "about" */ '../views/tlias/RoomView.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/tlias/LoginView.vue')
    },

    {
        path: '/chat',
        name: 'chat',
        component: () => import('../views/tlias/ChatView.vue')
    },
    {
        path: '/tour_reserve',
        name: 'tour_reserve',
        component: () => import('../views/tlias/Tour_ReserveView.vue')
    },
    {
        path: '/reserve',
        name: 'reserve',
        component: () => import('../views/tlias/ReserveView.vue')
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('../views/tlias/UserView.vue')
    }
]
const router = new VueRouter({
    routes,
})

export default router
